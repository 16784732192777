var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Auth',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-8 col-xl-8"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4"},[_c('div',{staticClass:"text-center w-75 m-auto"},[_c('div',{staticClass:"auth-logo"},[_c('router-link',{staticClass:"logo logo-dark text-center",attrs:{"to":"/"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":require("@/assets/images/logo-dark.png"),"alt":"","height":"120"}})])]),_c('router-link',{staticClass:"logo logo-light text-center",attrs:{"to":"/"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":require("@/assets/images/logo-light.png"),"alt":"","height":"120"}})])])],1),_c('p',{staticClass:"text-muted mb-4 mt-3"},[_vm._v(" Don't have an account? Create your own account, it takes less than a minute ")])]),(!_vm.loadingWizard)?_c('div',[_c('form',{attrs:{"action":"#"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"firstname"}},[_vm._v("Full Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.submitted && (_vm.$v.user.name.$error || _vm.nameLengthError),
                              },attrs:{"type":"text","id":"firstname","placeholder":"Enter your full name"},domProps:{"value":(_vm.user.name)},on:{"change":_vm.resetNameError,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Full name is required. ")]):_vm._e(),(_vm.submitted && _vm.nameLengthError)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your full name (at least 3 names). ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"fullname"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.user_name),expression:"user.user_name"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.user.user_name.$error,
                          },attrs:{"type":"text","id":"fullname","placeholder":"Enter your login username"},domProps:{"value":(_vm.user.user_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "user_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.user_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username is required. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showPassword)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                            },attrs:{"id":"password","placeholder":"Enter your password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.user.password)?_vm._i(_vm.user.password,null)>-1:(_vm.user.password)},on:{"change":function($event){var $$a=_vm.user.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.user, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.user, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.user, "password", $$c)}}}}):((_vm.showPassword)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                            },attrs:{"id":"password","placeholder":"Enter your password","type":"radio"},domProps:{"checked":_vm._q(_vm.user.password,null)},on:{"change":function($event){return _vm.$set(_vm.user, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                            },attrs:{"id":"password","placeholder":"Enter your password","type":_vm.showPassword},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"data-password":"false"},on:{"click":_vm.togglePassword}},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"password-eye"})])]),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e(),(_vm.submitted && !_vm.$v.user.password.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is too short at least 8 characters. ")]):_vm._e()])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showPassword2)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.passwordConfirm),expression:"user.passwordConfirm"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.passwordConfirm.$error,
                            },attrs:{"id":"password","placeholder":"Enter your password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.user.passwordConfirm)?_vm._i(_vm.user.passwordConfirm,null)>-1:(_vm.user.passwordConfirm)},on:{"change":function($event){var $$a=_vm.user.passwordConfirm,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.user, "passwordConfirm", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.user, "passwordConfirm", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.user, "passwordConfirm", $$c)}}}}):((_vm.showPassword2)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.passwordConfirm),expression:"user.passwordConfirm"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.passwordConfirm.$error,
                            },attrs:{"id":"password","placeholder":"Enter your password","type":"radio"},domProps:{"checked":_vm._q(_vm.user.passwordConfirm,null)},on:{"change":function($event){return _vm.$set(_vm.user, "passwordConfirm", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.passwordConfirm),expression:"user.passwordConfirm"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.passwordConfirm.$error,
                            },attrs:{"id":"password","placeholder":"Enter your password","type":_vm.showPassword2},domProps:{"value":(_vm.user.passwordConfirm)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "passwordConfirm", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"data-password":"false"},on:{"click":_vm.togglePassword2}},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"password-eye"})])]),(_vm.submitted && !_vm.$v.user.passwordConfirm.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e(),(_vm.submitted && !_vm.$v.user.passwordConfirm.sameAsPassword)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is not matched. ")]):_vm._e()])])]),_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phonenumber"}},[_vm._v("Phone number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.phone_number),expression:"user.phone_number"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.user.phone_number.$error,
                          },attrs:{"type":"text","id":"phonenumber","placeholder":"Enter your phone number"},domProps:{"value":(_vm.user.phone_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "phone_number", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.phone_number.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone number is required. ")]):_vm._e(),(_vm.submitted && !_vm.$v.user.phone_number.phoneNumberRegex)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone number is not valid. ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phonenumber"}},[_vm._v("Parent phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.parent_no),expression:"user.parent_no"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.parent_no.$error,
                            },attrs:{"type":"text","id":"phonenumber","placeholder":"Enter your parent's phone number"},domProps:{"value":(_vm.user.parent_no)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "parent_no", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.parent_no.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone number is required. ")]):_vm._e(),(_vm.submitted && !_vm.$v.user.parent_no.phoneNumberRegex)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone number is not valid. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phonenumber"}},[_vm._v("Parent phone 2 (Optional)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.parent_no2),expression:"user.parent_no2"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.parent_no2.$error,
                            },attrs:{"type":"text","id":"phonenumber","placeholder":"Enter your parent's phone number"},domProps:{"value":(_vm.user.parent_no2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "parent_no2", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.parent_no2.phoneNumberRegex)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone number is not valid. ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"school"}},[_vm._v("School name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.school),expression:"user.school"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.school.$error,
                            },attrs:{"type":"text","id":"school","placeholder":"Enter your school name"},domProps:{"value":(_vm.user.school)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "school", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.school.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" School name is required. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('b-form-group',{attrs:{"label":"Stage:","label-for":"input-C"}},[_c('b-form-select',{attrs:{"options":this.years,"state":_vm.yearEmpty},on:{"change":_vm.resetYearError},model:{value:(_vm.user.year),callback:function ($$v) {_vm.$set(_vm.user, "year", $$v)},expression:"user.year"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-2-live-feedback"}},[_vm._v("Year is required.")])],1)],1),_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('b-form-group',{attrs:{"label":"State:","label-for":"input-C"}},[_c('b-form-select',{attrs:{"options":this.statesOptions,"state":_vm.stateEmpty},on:{"change":_vm.resetStateError},model:{value:(_vm.user.countryState),callback:function ($$v) {_vm.$set(_vm.user, "countryState", $$v)},expression:"user.countryState"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-2-live-feedback"}},[_vm._v("State is required.")])],1)],1),_c('div',{staticClass:"col-sm-12 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"firstname"}},[_vm._v("Region")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.region),expression:"user.region"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.submitted && _vm.$v.user.region.$error,
                              },attrs:{"type":"text","id":"region","placeholder":"Enter your region "},domProps:{"value":(_vm.user.region)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "region", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.region.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your region (If outside Egypt, Put your Country). ")]):_vm._e()])])])]),_c('div',{staticClass:"row justify-content-center"},[_c('b-button',{staticStyle:{"width":"10rem"},attrs:{"variant":"primary"},on:{"click":_vm.tryToRegisterIn}},[_vm._v("Submit")])],1)]):_c('div',{staticClass:"loader"})])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"text-muted"},[_vm._v(" Already have account? "),_c('router-link',{staticClass:"text-primary font-weight-medium ml-1",attrs:{"to":"/login"}},[_vm._v("Sign In")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }