<script>
import Auth from "../../layouts/auth";
import appConfig from "../../../../app.config";
import errorHandler from '../../../helpers/errorHandler';
import { required, sameAs, minLength, helpers } from "vuelidate/lib/validators";
import XRegExp from 'xregexp';
import states from './states.json';
import Swal from "sweetalert2";
let phoneNumberRegex = helpers.regex('phoneNumberRegex',/^01[0-2,5]{1}[0-9]{8}$/)
/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [{ name: "description", content: appConfig.description }],
  },
  name: "account-register",
  data() {
    return {
      user: {
        name: "",
        user_name: "",
        password: "",
        passwordConfirm:"",
        phone_number:"",
        parent_no: "",
        parent_no2: "",
        school:"",
        year: null,
        status: false,
        countryState: null,
        region: "",
      },
      statesOptions: [],
      showPassword: "password",
      showPassword2: "password",
      submitted: false,
      regError: null,
      isRegisterError: false,
      yearEmpty: null,
      stateEmpty: null,
      loadingWizard: false,
      nameLengthError: false,
    
    };
  },
  components: {
    Auth,
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
    years(){
      return this.$store.getters['option/years'];
    }
  },
  validations: {
    user: {
      name: {
        required
      },
      user_name: {
        required,
      },
      password: {
        required,
        minLength : minLength(8)
      },
      passwordConfirm:{
        required,
        sameAsPassword: sameAs("password"),
      },
      phone_number:{
        required,
        phoneNumberRegex
      },
      parent_no:{
        required,
        phoneNumberRegex
      },
      parent_no2:{
        phoneNumberRegex
      },
      school:{
        required
      },
      year:{
        required
      },
      region:{
        required
      }
      
    },
  },
  async created() {
    await this.$store.dispatch('option/getYearOption');
    this.statesOptions = states;
  },
  methods: {
    extractNames() {
      const pattern = XRegExp("(?=(.*(?:\\p{L}+|\\p{Arabic}+)){3,}).*?(?:\\p{L}+|\\p{Arabic}+)(?:\\s+(?:\\p{L}+|\\p{Arabic}+)){2,}", "gu");
      const names = this.user.name.match(pattern) || null;
      return names;
    },
    validate(){
      if(this.user.year == null){
          this.yearEmpty = false
      }else{
        this.yearEmpty = true
      }
      if(this.user.countryState == null){
          this.stateEmpty = false
      }else{
        this.stateEmpty = true
      }
      if(this.extractNames() == null){
        this.nameLengthError = true;
      }else{
        this.nameLengthError = false;
      }
      return !this.yearEmpty || this.nameLengthError || !this.stateEmpty;
    },
    // Try to register the user in with the email, username
    // and password they provided.
    async tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.user.$touch();
      let check = await this.validate()
      if (this.$v.user.$invalid || check) {
        return;
      } else {
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.$store
              .dispatch("user/registerUserStudent", {
                name: this.user.name.trim(),
                user_name: this.user.user_name.trim(),
                password: this.user.password,
                phone_number: '2' + this.user.phone_number,
                phone_no_1: '2' + this.user.parent_no,
                phone_no_2: (this.user.parent_no2 != '')?'2' + this.user.parent_no2:null,
                school: this.user.school,
                year: this.user.year,
                countryState: this.user.countryState,
                region: this.user.region,
                status: false
              })
              // eslint-disable-next-line no-unused-vars
              .then( async (token) => {
                this.isRegisterError = false;
                Swal.fire({
                  icon: "success",
                  title: "You registered your account!",
                  showConfirmButton: false,
                  timer: 1500
                }).then(()=>{
                  this.$router.push('/login')
                });
              })
              .catch((error) => {
                if(error.response.data.detail["error message"]){
                  errorHandler.methods.error(error);
                }else{
                  errorHandler.methods.errorMessage(error);
                }
                this.regError = (error.response.data.detail["error message"])?error.response.data.detail["error message"]:error.response.data.detail.errors[0];
                this.isRegisterError = true;
              })
          );
        
      }
    },
    togglePassword(){
      if(this.showPassword == "password"){
        this.showPassword = "text";
      }else{
        this.showPassword = "password";
      }
    },
    togglePassword2(){
      if(this.showPassword2 == "password"){
        this.showPassword2 = "text";
      }else{
        this.showPassword2 = "password";
      }
    },
    resetYearError(){
      this.yearEmpty = null;
    },
    resetStateError(){
      this.stateEmpty = null;
    },
    resetNameError(){
      this.nameLengthError = null;
    },
    setLoading(value){
      this.loadingWizard = value;
    },
    goLogin(){
      this.$router.push({
        path:'/login'
      })
    }
  },
};
</script>

<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-8 col-xl-8">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/logo-dark.png" alt height="120" />
                  </span>
                </router-link>

                <router-link to="/" class="logo logo-light text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/logo-light.png" alt height="120" />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                Don't have an account? Create your own account, it takes less
                than a minute
              </p>
            </div>
              <div v-if="!loadingWizard">
                <form action="#">
                  <div class="row">
                          <div class="col-sm-12 col-xl-12">
                            <div class="form-group">
                              <label for="firstname">Full Name</label>
                              <input
                                class="form-control"
                                v-model="user.name"
                                type="text"
                                id="firstname"
                                placeholder="Enter your full name"
                                :class="{
                                  'is-invalid': submitted && ($v.user.name.$error || nameLengthError),
                                }"
                                @change="resetNameError"
                              />
                              <div
                                v-if="submitted && !$v.user.name.required"
                                class="invalid-feedback"
                              >
                                Full name is required.
                              </div>
                              <div
                                v-if="submitted && nameLengthError"
                                class="invalid-feedback"
                              >
                                Please enter your full name (at least 3 names).
                              </div>
                            </div>
                          </div>
                      <!-- end col -->
                  </div>
                  <!-- end row -->
                    <div class="row">
                      <div class="col-sm-12 col-xl-6">
                        <div class="form-group">
                          <label for="fullname">Username</label>
                          <input
                            class="form-control"
                            v-model="user.user_name"
                            type="text"
                            id="fullname"
                            placeholder="Enter your login username"
                            :class="{
                              'is-invalid': submitted && $v.user.user_name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.user.user_name.required"
                            class="invalid-feedback"
                          >
                            Username is required.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-xl-6">
                        <div class="form-group">
                          <label for="password">Password</label>
                          <div class="input-group input-group-merge">
                            <input
                              :type="showPassword"
                              v-model="user.password"
                              id="password"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.user.password.$error,
                              }"
                              placeholder="Enter your password"
                            />
                            <div class="input-group-append" data-password="false" @click="togglePassword">
                              <div class="input-group-text">
                                <span class="password-eye"></span>
                              </div>
                            </div>
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                            <div
                              v-if="submitted && !$v.user.password.minLength"
                              class="invalid-feedback"
                            >
                              Password is too short at least 8 characters.
                            </div>
                          </div>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-xl-6">
                        <div class="form-group">
                          <label for="password">Confirm Password</label>
                          <div class="input-group input-group-merge">
                            <input
                              :type="showPassword2"
                              v-model="user.passwordConfirm"
                              id="password"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.user.passwordConfirm.$error,
                              }"
                              placeholder="Enter your password"
                            />
                            <div class="input-group-append" data-password="false" @click="togglePassword2">
                              <div class="input-group-text">
                                <span class="password-eye"></span>
                              </div>
                            </div>
                            <div
                              v-if="submitted && !$v.user.passwordConfirm.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                            <div
                              v-if="submitted && !$v.user.passwordConfirm.sameAsPassword"
                              class="invalid-feedback"
                            >
                              Password is not matched.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-xl-6">
                        <div class="form-group">
                          <label for="phonenumber">Phone number</label>
                          <input
                            class="form-control"
                            v-model="user.phone_number"
                            type="text"
                            id="phonenumber"
                            placeholder="Enter your phone number"
                            :class="{
                              'is-invalid': submitted && $v.user.phone_number.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.user.phone_number.required"
                            class="invalid-feedback"
                          >
                            Phone number is required.
                          </div>
                          <div
                            v-if="submitted && !$v.user.phone_number.phoneNumberRegex"
                            class="invalid-feedback"
                          >
                            Phone number is not valid.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-xl-6">
                          <div class="form-group">
                            <label for="phonenumber">Parent phone</label>
                            <input
                              class="form-control"
                              v-model="user.parent_no"
                              type="text"
                              id="phonenumber"
                              placeholder="Enter your parent's phone number"
                              :class="{
                                'is-invalid': submitted && $v.user.parent_no.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.parent_no.required"
                              class="invalid-feedback"
                            >
                              Phone number is required.
                            </div>
                            <div
                              v-if="submitted && !$v.user.parent_no.phoneNumberRegex"
                              class="invalid-feedback"
                            >
                              Phone number is not valid.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-xl-6">
                          <div class="form-group">
                            <label for="phonenumber">Parent phone 2 (Optional)</label>
                            <input
                              class="form-control"
                              v-model="user.parent_no2"
                              type="text"
                              id="phonenumber"
                              placeholder="Enter your parent's phone number"
                              :class="{
                                'is-invalid': submitted && $v.user.parent_no2.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.parent_no2.phoneNumberRegex"
                              class="invalid-feedback"
                            >
                              Phone number is not valid.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-xl-6">
                          <div class="form-group">
                            <label for="school">School name</label>
                            <input
                              class="form-control"
                              v-model="user.school"
                              type="text"
                              id="school"
                              placeholder="Enter your school name"
                              :class="{
                                'is-invalid': submitted && $v.user.school.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.school.required"
                              class="invalid-feedback"
                            >
                              School name is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-xl-6">
                          <b-form-group label="Stage:" label-for="input-C" >
                            <b-form-select v-model="user.year" :options="this.years" :state="yearEmpty" @change="resetYearError"></b-form-select>
                            <b-form-invalid-feedback id="input-2-live-feedback">Year is required.</b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-xl-6">
                          <b-form-group label="State:" label-for="input-C" >
                            <b-form-select v-model="user.countryState" :options="this.statesOptions" :state="stateEmpty" @change="resetStateError"></b-form-select>
                            <b-form-invalid-feedback id="input-2-live-feedback">State is required.</b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-xl-6">
                            <div class="form-group">
                              <label for="firstname">Region</label>
                              <input
                                class="form-control"
                                v-model="user.region"
                                type="text"
                                id="region"
                                placeholder="Enter your region "
                                :class="{
                                  'is-invalid': submitted && $v.user.region.$error,
                                }"
                              />
                              <div
                                v-if="submitted && !$v.user.region.required"
                                class="invalid-feedback"
                              >
                                Please enter your region (If outside Egypt, Put your Country).
                              </div>
                            </div>
                          </div>
                      </div>
                    </form>
                    <div class="row justify-content-center">
                      <b-button variant="primary" style="width: 10rem;" @click="tryToRegisterIn">Submit</b-button>
                    </div>
                  </div>
              <div class="loader" v-else></div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">
              Already have account?
              <router-link
                to="/login"
                class="text-primary font-weight-medium ml-1"
                >Sign In</router-link
              >
            </p>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>

<style scoped>
/* This is a css loader. It's not related to vue-form-wizard */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #3bafda;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>